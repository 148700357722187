import React from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Container} from 'semantic-ui-react';
import logoFooter from 'assets/images/logo.svg';
import logoFacebook from 'assets/images/icon-facebook.svg';
import logoInstagram from 'assets/images/icon-instagram.svg';
import logoLinkedin from 'assets/images/icon-linkedin.svg';
import logoTwitter from 'assets/images/icon-twitter.svg';
import {Link} from 'react-router-dom';
import {ROUTES} from 'utils';
import ShareHolder from 'components/common/ShareHolder/ShareHolder';
import ContactUsModal from 'components/common/ContactUsModal/ContactUsModal';

const Footer = ({containerWidth}) => (
    <footer id="footer">
        <Container style={containerWidth && {width: containerWidth}}>
            <div className="footer-wrapper">
                <a className="logo-footer" href="../index.html">
                    <img src={logoFooter} alt="bionabu"/>
                </a>
                <div className="footer-info">
                    <p>Company No. 11083899</p>
                    <p>Registered in England and Wales</p>
                </div>
                <div className="footer-social-list">
                    <a href="https://www.linkedin.com/company/bionabu/" target="_blank"
                       rel="noreferrer">
                        <img src={logoLinkedin} alt="linkedin"/>
                    </a>
                    <a href="https://www.instagram.com/bio_nabu/" target="_blank" rel="noreferrer">
                        <img src={logoInstagram} alt="instagram"/>
                    </a>
                </div>
                <div className="copyright">© Copyright Bionabu 2024</div>

                <div className="footer-page-links">
                    <div>
                        <ul>
                            <li>
                                <Link to={ROUTES.MAIN_PAGE} target="_blank">
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link to={ROUTES.NEST} target="_blank">
                                    Community
                                </Link>
                            </li>
                            <li>
                                <Link to={ROUTES.ABOUT_US} target="_blank">
                                    About Us
                                </Link>
                            </li>
                            <li>
                                <Link to={ROUTES.FAQ} target="_blank">
                                    FAQ
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="footer-terms-links">
                    <div>
                        <ul>
                            <li>
                                <Link to={ROUTES.TERMS_OF_SERVICE} target="_blank">
                                    Terms of Service
                                </Link>
                            </li>
                            <li>
                                <Link to={ROUTES.PRIVACY_POLICY} target="_blank">
                                    Privacy Policy
                                </Link>
                            </li>
                            <li>
                                <Link to={ROUTES.COOKIE_POLICY} target="_blank">
                                    Cookie Policy
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="footer-book">
                    <Button fluid as={Link} to={{pathname: 'https://calendly.com/bionabu/book-a-demo'}} target="_blank" className='button-rounded-gradient'>
                        Book a Demo
                    </Button>
                </div>
            </div>
        </Container>
    </footer>
);

export default observer(Footer);
